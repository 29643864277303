// // import './vendor/modernizr'
import _debounce from "lodash/debounce";
import "./vendor/jquery.flip";

// // same width - height box handler
// const squaresHandler = () => {
//     const $squares = $('.square');
//     if ($squares.length > 0) {
//         $squares.each(function (i, square) {
//             const squareWidth = $(square).outerWidth()
//             $(square).height(squareWidth)
//         })
//     }
// }
//
// // flip boxes effect - jquery plugin
// const flipBoxesHandler = () => {
//     const $flipBoxes = $('.flip');
//     if ($flipBoxes.length > 0) {
//         $flipBoxes.flip()
//     }
//
// }
//
// //TODO work in progress
// const scrollTo = () => {
//     const $zdravaEnergia = $('#zdrava-energia');
//
//     if ($zdravaEnergia.length > 0) {
//
//         const $posTop = $zdravaEnergia.position()['top'];
//
//         $('#test').on('click', () => {
//             $('html, body').animate({
//                 scrollTop: $posTop
//             }, 2000, 'swing');
//         });
//     }
// }

// const initializerHandler = () => {
//     // squaresHandler();
//     // scrollTo();
// }

window.scrollToElement = (el) => {
  $("html, body").animate({ scrollTop: $(el).offset().top }, 500);
};

// document.addEventListener('DOMContentLoaded', function () {
//
//     $(window).on('resize', _debounce(initializerHandler, 500))
//
//     // initializerHandler();
//     // flipBoxesHandler();
//
// })

$(window).on("load", () => {
  $("#video-porridge").on("show.bs.modal", function(e) {
    $("#video-porridge iframe").attr(
      "src",
      "https://www.youtube-nocookie.com/embed/3UrD6cTwZUg?rel=0&autoplay=1"
    );
  });
  $("#video-porridge").on("hide.bs.modal", function(e) {
    $("#video-porridge iframe").attr("src", "");
  });

  $("#video-dobrucha").on("show.bs.modal", function(e) {
    $("#video-dobrucha iframe").attr(
      "src",
      "https://www.youtube-nocookie.com/embed/3w1H6u8QVYk?rel=0&autoplay=1"
    );
  });

  $("#video-dobrucha").on("hide.bs.modal", function(e) {
    $("#video-dobrucha iframe").attr("src", "");
  });

  $("#video-joya-1").on("show.bs.modal", function(e) {
    $("#video-joya-1 iframe").attr(
      "src",
      "https://www.youtube-nocookie.com/embed/8gHmWaMm-bs?rel=0&autoplay=1"
    );
  });

  $("#video-joya-1").on("hide.bs.modal", function(e) {
    $("#video-joya-1 iframe").attr("src", "");
  });

  $("#video-joya-2").on("show.bs.modal", function(e) {
    $("#video-joya-2 iframe").attr(
      "src",
      "https://www.youtube-nocookie.com/embed/oFXbsr0lOmA?rel=0&autoplay=1"
    );
  });

  $("#video-joya-2").on("hide.bs.modal", function(e) {
    $("#video-joya-2 iframe").attr("src", "");
  });

  $("#video1").on("show.bs.modal", function(e) {
    $("#video1 iframe").attr(
      "src",
      "https://www.youtube-nocookie.com/embed/RMFVd6ZJvH8?rel=0&autoplay=1"
    );
  });

  $("#video1").on("hide.bs.modal", function(e) {
    $("#video1 iframe").attr("src", "");
  });

  $("#video2").on("show.bs.modal", function(e) {
    $("#video2 iframe").attr(
      "src",
      "https://www.youtube-nocookie.com/embed/8v85FHhTTRs?rel=0&autoplay=1"
    );
  });

  $("#video2").on("hide.bs.modal", function(e) {
    $("#video2 iframe").attr("src", "");
  });

  $("#video3").on("show.bs.modal", function(e) {
    $("#video3 iframe").attr(
      "src",
      "https://www.youtube-nocookie.com/embed/iPdOsnY7N9g?rel=0&autoplay=1"
    );
  });

  $("#video3").on("hide.bs.modal", function(e) {
    $("#video3 iframe").attr("src", "");
  });

  const carousel = $(".other-categories__carousel");

  if (carousel.length) {
    carousel.slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      dots: true,
      arrows: false,
      centerMode: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
});
